import "./App.css";
import React, { Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import ErrorPage from "../ErrorPage/ErrorPage";
import { Helmet } from "react-helmet";
import Referal from "../Referal/Referal";

const Home = React.lazy(() => import("../Home/Home"));
const Thanks = React.lazy(() => import("../Thanks/Thanks"));

function App() {
  const [isOpenPopupForm, setOpenPopupForm] = React.useState(false);
  const [isOpenPopupImg, setOpenPopupImg] = React.useState(false);
  const [isOpenPopupArticle, setOpenPopupArticle] = React.useState(false);
  const [isPopupImg, setPopupImg] = React.useState();
  const [isTitle, setTitle] = React.useState("");
  const [isId, setId] = React.useState("");
  const [isDate, setDate] = React.useState("");
  const [isArticle, setArticle] = React.useState([]);
  const [isThanks, setThanks] = React.useState(false);
  // Hook
  const useOnScreen = (options) => {
    const ref = React.useRef();
    const [visible, setVisible] = React.useState(false);
    React.useEffect(() => {
      const observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          setVisible(true);
        }
      }, options);
      if (ref.current) {
        observer.observe(ref.current);
      }
      return () => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      };
    }, [ref, options]);
    return [ref, visible];
  };
  function isOpen(s) {
    setOpenPopupImg(true);
    setPopupImg({ src: s.src, webp: s.webp, avif: s.avif });
  }
  function onClose() {
    setOpenPopupForm(false);
    setOpenPopupImg(false);
    setOpenPopupArticle(false);
  }
  const [dimensions, setDimensions] = React.useState({
    width: window.innerWidth,
  });
  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
    });
  };
  React.useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);
  return (
    <div className="app">
      <Suspense fallback={<div></div>}>
        <Routes>
          <Route
            path="/"
            element={
              <Home
                isOpenPopupForm={isOpenPopupForm}
                setOpenPopupForm={setOpenPopupForm}
                isOpenPopupImg={isOpenPopupImg}
                setOpenPopupImg={setOpenPopupImg}
                isOpenPopupArticle={isOpenPopupArticle}
                setOpenPopupArticle={setOpenPopupArticle}
                isPopupImg={isPopupImg}
                setPopupImg={setPopupImg}
                isTitle={isTitle}
                setTitle={setTitle}
                isId={isId}
                setId={setId}
                isDate={isDate}
                setDate={setDate}
                isArticle={isArticle}
                setArticle={setArticle}
                isThanks={isThanks}
                setThanks={setThanks}
                isOpen={isOpen}
                onClose={onClose}
                dimensions={dimensions}
                setDimensions={setDimensions}
                handleResize={handleResize}
                useOnScreen={useOnScreen}
              >
                <Helmet>
                  <meta
                    name="description"
                    content="Студия интернет рекламы Владимира Попова. Бесплатный аудит текущей рекламной кампании. Кейсы и полезные видео для бизнеса."
                  />
                  <title>
                    Студия интернет рекламы "Владимира Попова". Настройка
                    контекстной рекламы в Яндекс Директ, Вконтакте,
                    Одноклассниках и Яндекс ДЗЕН
                  </title>
                  <link rel="canonical" href="https://popov.media/" />
                  <meta name="robots" content="index,follow" />
                  <meta name="keywords" content="" />
                  <meta
                    property="og:title"
                    content='Студия интернет рекламы "Владимира Попова". Настройка контекстной рекламы
      в Яндекс Директ, Вконтакте, Одноклассниках и Яндекс ДЗЕН'
                  />
                  <meta
                    property="og:description"
                    content="Студия интернет рекламы Владимира Попова. Бесплатный аудит текущей рекламной кампании. Кейсы и полезные видео для бизнеса."
                  />
                </Helmet>
              </Home>
            }
          >
            <Route
              path="/thank-you"
              element={
                isThanks ? (
                  <Thanks Helmet={Helmet}>
                    <Helmet>
                      <meta
                        name="description"
                        content="Спасибо за заявку, мы свяжемся с Вами в ближайшее время!"
                      />
                      <title>Спасибо</title>
                      <link rel="canonical" href="https://popov.media/" />
                      <meta name="robots" content="none" />
                      <meta name="keywords" content="" />
                      <meta property="og:title" content="Спасибо" />
                      <meta
                        property="og:description"
                        content="Спасибо за заявку, мы свяжемся с Вами в ближайшее время!"
                      />
                    </Helmet>
                  </Thanks>
                ) : (
                  <Navigate to="/" />
                )
              }
            />
          </Route>
          <Route
            path="/ads_game"
            element={
              <Referal
                useOnScreen={useOnScreen}
                setOpenPopup={setOpenPopupForm}
              >
                <Helmet>
                  <meta
                    name="description"
                    content="Активные рефералы для любых игр-кликеров (тапалок). БЕЗ БОТОВ. ЖИВЫЕ
            рефералы с разными уровнями."
                  />
                  <title>
                    Активные рефералы для любых игр-кликеров (тапалок). БЕЗ
                    БОТОВ. ЖИВЫЕ рефералы с разными уровнями.
                  </title>
                  <link rel="canonical" href="https://popov.media/" />
                  <meta name="robots" content="index,follow" />
                  <meta name="keywords" content="" />
                  <meta
                    property="og:title"
                    content="Активные рефералы для любых игр-кликеров (тапалок). БЕЗ БОТОВ. ЖИВЫЕ
            рефералы с разными уровнями."
                  />
                  <meta
                    property="og:description"
                    content="Активные рефералы для любых игр-кликеров (тапалок). БЕЗ БОТОВ. ЖИВЫЕ
            рефералы с разными уровнями."
                  />
                </Helmet>
              </Referal>
            }
          />
          <Route
            status={404}
            path="/404"
            element={
              <ErrorPage>
                <Helmet>
                  <meta
                    name="description"
                    content="Ошибка 404. К сожалению такой страницы не существует. Возможно она была перемещена, или Вы просто неверно указали адрес страницы."
                  />
                  <title>Ошибка 404</title>
                  <link rel="canonical" href="https://popov.media/" />
                  <meta name="robots" content="noindex" />
                  <meta name="keywords" content="" />
                  <meta property="og:title" content="Ошибка 404" />
                  <meta
                    property="og:description"
                    content="Ошибка 404. К сожалению такой страницы не существует. Возможно она была перемещена, или Вы просто неверно указали адрес страницы."
                  />
                </Helmet>
              </ErrorPage>
            }
          />
          <Route
            path="*"
            element={<Navigate to="/404" replace status={404} />}
          />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;

import "./Footer.css";
import logo from "../../images/logo.png";

function Footer() {
  return (
    <footer className="footer">
      <h2 className="section__title footer__title">
        <span className="section__title-span">свяжитесь</span> с&nbsp;нами
      </h2>
      <h3 className="footer__subtitle">
        И получите бесплатную консультацию или что-нибудь ещё
      </h3>
      <a className="footer__link" href="tel:+79616570661">
        8 (961) 657-06-61
      </a>
      <a className="footer__link" href="mailto:info@popov.media">
        info@popov.media
      </a>
      <div className="footer__box">
        <a
          className="footer__social-link"
          target="_blank"
          rel="noreferrer"
          href="https://vk.com/blogpopov"
        >
          {null}
        </a>
        <a
          className="footer__social-link"
          target="_blank"
          rel="noreferrer"
          href="https://wa.me/79616570661?text=Здравствуйте.+Нужна+консультация+по+интернет-рекламе."
        >
          {null}
        </a>
        <a
          className="footer__social-link"
          target="_blank"
          rel="noreferrer"
          href="https://www.youtube.com/channel/UCachJ8zDbORExA-rbJ5w6mw"
        >
          {null}
        </a>
        <a
          className="footer__social-link"
          target="_blank"
          rel="noreferrer"
          href="https://t.me/teampopov"
        >
          {null}
        </a>
      </div>
      <img className="footer__logo" src={logo} alt="Logo" />
      <a className="footer__policy" href="./privacy.pdf">
        Политика конфиденциальности
      </a>
      <p className="footer__text">ИП Попов Владимир Владимирович</p>
      <p className="footer__text">ИНН: 344816474824 ОГРНИП: 320344300054405</p>
      <p className="footer__text">
        Адрес: 400051, Волгоградская обл., г. Волгоград, ул. 40 лет ВЛКСМ, д. 20
      </p>
      <p className="footer__text">
        р/с: 40802810309500022832 в ТОЧКА ПАО БАНКА "ФК ОТКРЫТИЕ" БИК: 044525999
      </p>
    </footer>
  );
}

export default Footer;

import "./Header.css";
import React from "react";
import logo from "../../images/logo.png";
import logowebp from "../../images/logo.webp";
import logoavif from "../../images/logo.avif";

function Header() {
  return (
    <header className="header">
      <div className="header__box">
        <picture>
          <source srcSet={logowebp} type="image/webp" />
          <source srcSet={logoavif} type="image/avif" />
          <source srcSet={logo} type="image/png" />
          <img className="header_logo" src={logo} alt="Logo" />
        </picture>
        <p className="header_logo-text">Студия Владимира Попова</p>
        <li className="header_text">
          <span className="header_text-span">Интернет реклама</span>
        </li>
        <div className="header__box-mess">
          <a
            className="autoblick_small header__social-link"
            href="tel:+79616570661"
          >
            {null}
          </a>
          <a
            className="header__social-link"
            target="_blank"
            rel="noreferrer"
            href="https://wa.me/79616570661?text=Здравствуйте.+Нужна+консультация+по+интернет-рекламе."
          >
            {null}
          </a>
          <a
            className="header__social-link"
            target="_blank"
            rel="noreferrer"
            href="https://t.me/teampopov"
          >
            {null}
          </a>
        </div>
      </div>
    </header>
  );
}

export default Header;

import "./ErrorPage.css";
import React from "react";
import { useNavigate } from "react-router-dom";
import Header from "../Header/Header";

function ErrorPage(props) {
  const [isX, setX] = React.useState("");
  const [isY, setY] = React.useState("");
  const history = useNavigate();
  return (
    <>
      {props.children}
      <Header />
      <section className="error-page">
        <div className="error-page__box">
          <h1 className="error-page__title">ОШИБКА 404</h1>
          <p className="error-page__subtitle">
            К сожалению такой страницы не существует. Возможно она была
            перемещена, или Вы просто неверно указали адрес страницы.
          </p>
          <button
            className="error-page__button autoblick circleflash"
            onClick={() => history("/")}
            onMouseEnter={(e) => {
              setX(e.screenX - e.currentTarget.getBoundingClientRect().x);
              setY(e.screenY - e.currentTarget.getBoundingClientRect().y);
            }}
          >
            <p className="button__text">На главную</p>
            <span style={{ top: isY + "px", left: isX + "px" }}></span>
          </button>
        </div>
      </section>
    </>
  );
}

export default ErrorPage;

import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import "./Referal.css";
import React from "react";
import HK from "../../images/ref/1.jpg";
import Blum from "../../images/ref/2.png";
import Catizen from "../../images/ref/3.jpg";
import TapSwap from "../../images/ref/4.png";
import MemeFi from "../../images/ref/5.webp";
import Arbuz from "../../images/ref/6.jpg";
import YesCoin from "../../images/ref/7.png";
import LimeCoin from "../../images/ref/8.png";
import EmpiresBattle from "../../images/ref/9.jpg";
import Hyper from "../../images/ref/10.png";
import Iceberg from "../../images/ref/11.png";
import PixelTap from "../../images/ref/12.png";

function Referal(props) {
  const [isX, setX] = React.useState("");
  const [isY, setY] = React.useState("");
  const [refPrice, priceIsVisible] = props.useOnScreen({
    rootMargin: "5px",
  });
  return (
    <>
      {props.children}
      <Header />
      <section className="referal">
        <div className="referal__box">
          <h1 className="referal__title">
            Активные рефералы для любых игр-кликеров (тапалок). БЕЗ БОТОВ. ЖИВЫЕ
            рефералы с разными уровнями.
          </h1>
          <p className="referal__subtitle">
            Hamster Kombat, Blum, Catizen, TapSwap, MemeFi, КликниАрбуз,
            YesCoin, LimeCoin, EmpiresBattle, Hyper, Iceberg, PixelTap и другие.
          </p>
          <a
            className="referal__button autoblick circleflash"
            onMouseEnter={(e) => {
              setX(e.screenX - e.currentTarget.getBoundingClientRect().x);
              setY(e.screenY - e.currentTarget.getBoundingClientRect().y);
            }}
            target="_blank"
            rel="noreferrer"
            href="https://t.me/teampopov"
          >
            <p className="button__text">Написать в Telegram</p>

            <span style={{ top: isY + "px", left: isX + "px" }}></span>
          </a>
        </div>
      </section>

      <section className="price-ref" ref={refPrice}>
        <h2 className="section__title">
          Стоимость <span className="section__title-span">за реферала</span>
        </h2>
        {priceIsVisible ? (
          <div className="price-ref_container">
            <div className="price-ref__box">
              <div className="price-ref__title-box">
                <img className="price-ref__img" src={HK} alt="Humster Kombat" />
                <h3 className="price-ref__title">Humster Kombat</h3>
              </div>
              <p className="price-ref__about">
                Качественные рефералы Hamster Kombat, БЕЗ БОТОВ (Активные, живые
                игроки). Различные уровни.
              </p>
              <p className="price-ref__price">32 &#8381;/реферал</p>
              <div className="price-ref__price-box">
                <a
                  className="price-ref__button"
                  target="_blank"
                  rel="noreferrer"
                  href="https://t.me/teampopov"
                >
                  Заказать в Telegram
                </a>
              </div>
            </div>
            <div className="price-ref__box">
              <div className="price-ref__title-box">
                <img className="price-ref__img" src={Blum} alt="Blum" />
                <h3 className="price-ref__title">Blum</h3>
              </div>
              <p className="price-ref__about">
                Качественные рефералы Blum, БЕЗ БОТОВ (Активные, живые игроки).
                Различные уровни.
              </p>
              <p className="price-ref__price">29 &#8381;/реферал</p>
              <div className="price-ref__price-box">
                <a
                  className="price-ref__button"
                  target="_blank"
                  rel="noreferrer"
                  href="https://t.me/teampopov"
                >
                  Заказать в Telegram
                </a>
              </div>
            </div>
            <div className="price-ref__box">
              <div className="price-ref__title-box">
                <img className="price-ref__img" src={Catizen} alt="Catizen" />
                <h3 className="price-ref__title">Catizen</h3>
              </div>
              <p className="price-ref__about">
                Качественные рефералы Catizen, БЕЗ БОТОВ (Активные, живые
                игроки). Различные уровни.
              </p>
              <p className="price-ref__price">30 &#8381;/реферал</p>
              <div className="price-ref__price-box">
                <a
                  className="price-ref__button"
                  target="_blank"
                  rel="noreferrer"
                  href="https://t.me/teampopov"
                >
                  Заказать в Telegram
                </a>
              </div>
            </div>
            <div className="price-ref__box">
              <div className="price-ref__title-box">
                <img className="price-ref__img" src={TapSwap} alt="TapSwap" />
                <h3 className="price-ref__title">TapSwap</h3>
              </div>
              <p className="price-ref__about">
                Качественные рефералы TapSwap, БЕЗ БОТОВ (Активные, живые
                игроки). Различные уровни.
              </p>
              <p className="price-ref__price">27 &#8381;/реферал</p>
              <div className="price-ref__price-box">
                <a
                  className="price-ref__button"
                  target="_blank"
                  rel="noreferrer"
                  href="https://t.me/teampopov"
                >
                  Заказать в Telegram
                </a>
              </div>
            </div>
            <div className="price-ref__box">
              <div className="price-ref__title-box">
                <img className="price-ref__img" src={MemeFi} alt="MemeFi" />
                <h3 className="price-ref__title">MemeFi</h3>
              </div>
              <p className="price-ref__about">
                Качественные рефералы MemeFi, БЕЗ БОТОВ (Активные, живые
                игроки). Различные уровни.
              </p>
              <p className="price-ref__price">29 &#8381;/реферал</p>
              <div className="price-ref__price-box">
                <a
                  className="price-ref__button"
                  target="_blank"
                  rel="noreferrer"
                  href="https://t.me/teampopov"
                >
                  Заказать в Telegram
                </a>
              </div>
            </div>
            <div className="price-ref__box">
              <div className="price-ref__title-box">
                <img className="price-ref__img" src={Arbuz} alt="КликниАрбуз" />
                <h3 className="price-ref__title">КликниАрбуз</h3>
              </div>
              <p className="price-ref__about">
                Качественные рефералы КликниАрбуз, БЕЗ БОТОВ (Активные, живые
                игроки). Различные уровни.
              </p>
              <p className="price-ref__price">21 &#8381;/реферал</p>
              <div className="price-ref__price-box">
                <a
                  className="price-ref__button"
                  target="_blank"
                  rel="noreferrer"
                  href="https://t.me/teampopov"
                >
                  Заказать в Telegram
                </a>
              </div>
            </div>
            <div className="price-ref__box">
              <div className="price-ref__title-box">
                <img className="price-ref__img" src={YesCoin} alt="YesCoin" />
                <h3 className="price-ref__title">YesCoin</h3>
              </div>
              <p className="price-ref__about">
                Качественные рефералы YesCoin, БЕЗ БОТОВ (Активные, живые
                игроки). Различные уровни.
              </p>
              <p className="price-ref__price">25 &#8381;/реферал</p>
              <div className="price-ref__price-box">
                <a
                  className="price-ref__button"
                  target="_blank"
                  rel="noreferrer"
                  href="https://t.me/teampopov"
                >
                  Заказать в Telegram
                </a>
              </div>
            </div>
            <div className="price-ref__box">
              <div className="price-ref__title-box">
                <img className="price-ref__img" src={LimeCoin} alt="LimeCoin" />
                <h3 className="price-ref__title">LimeCoin</h3>
              </div>
              <p className="price-ref__about">
                Качественные рефералы LimeCoin, БЕЗ БОТОВ (Активные, живые
                игроки). Различные уровни.
              </p>
              <p className="price-ref__price">28 &#8381;/реферал</p>
              <div className="price-ref__price-box">
                <a
                  className="price-ref__button"
                  target="_blank"
                  rel="noreferrer"
                  href="https://t.me/teampopov"
                >
                  Заказать в Telegram
                </a>
              </div>
            </div>
            <div className="price-ref__box">
              <div className="price-ref__title-box">
                <img
                  className="price-ref__img"
                  src={EmpiresBattle}
                  alt="EmpiresBattle"
                />
                <h3 className="price-ref__title">EmpiresBattle</h3>
              </div>
              <p className="price-ref__about">
                Качественные рефералы EmpiresBattle, БЕЗ БОТОВ (Активные, живые
                игроки). Различные уровни.
              </p>
              <p className="price-ref__price">29 &#8381;/реферал</p>
              <div className="price-ref__price-box">
                <a
                  className="price-ref__button"
                  target="_blank"
                  rel="noreferrer"
                  href="https://t.me/teampopov"
                >
                  Заказать в Telegram
                </a>
              </div>
            </div>
            <div className="price-ref__box">
              <div className="price-ref__title-box">
                <img className="price-ref__img" src={Hyper} alt="Hyper" />
                <h3 className="price-ref__title">Hyper</h3>
              </div>
              <p className="price-ref__about">
                Качественные рефералы Hyper, БЕЗ БОТОВ (Активные, живые игроки).
                Различные уровни.
              </p>
              <p className="price-ref__price">30 &#8381;/реферал</p>
              <div className="price-ref__price-box">
                <a
                  className="price-ref__button"
                  target="_blank"
                  rel="noreferrer"
                  href="https://t.me/teampopov"
                >
                  Заказать в Telegram
                </a>
              </div>
            </div>
            <div className="price-ref__box">
              <div className="price-ref__title-box">
                <img className="price-ref__img" src={Iceberg} alt="Iceberg" />
                <h3 className="price-ref__title">Iceberg</h3>
              </div>
              <p className="price-ref__about">
                Качественные рефералы Iceberg, БЕЗ БОТОВ (Активные, живые
                игроки). Различные уровни.
              </p>
              <p className="price-ref__price">27 &#8381;/реферал</p>
              <div className="price-ref__price-box">
                <a
                  className="price-ref__button"
                  target="_blank"
                  rel="noreferrer"
                  href="https://t.me/teampopov"
                >
                  Заказать в Telegram
                </a>
              </div>
            </div>
            <div className="price-ref__box">
              <div className="price-ref__title-box">
                <img className="price-ref__img" src={PixelTap} alt="PixelTap" />
                <h3 className="price-ref__title">PixelTap</h3>
              </div>
              <p className="price-ref__about">
                Качественные рефералы PixelTap, БЕЗ БОТОВ (Активные, живые
                игроки). Различные уровни.
              </p>
              <p className="price-ref__price">22 &#8381;/реферал</p>
              <div className="price-ref__price-box">
                <a
                  className="price-ref__button"
                  target="_blank"
                  rel="noreferrer"
                  href="https://t.me/teampopov"
                >
                  Заказать в Telegram
                </a>
              </div>
            </div>
            <div className="price-ref__box">
              <h3 className="price-ref__title">Другие рефералки</h3>
              <p className="price-ref__about">
                Качественные рефералы любых программ, БЕЗ БОТОВ (Активные, живые
                игроки).
              </p>
              <p className="price-ref__price">от 22 &#8381;/реферал</p>
              <div className="price-ref__price-box">
                <a
                  className="price-ref__button"
                  target="_blank"
                  rel="noreferrer"
                  href="https://t.me/teampopov"
                >
                  Заказать в Telegram
                </a>
              </div>
            </div>
          </div>
        ) : null}
      </section>
      <Footer />
    </>
  );
}

export default Referal;
